(function ($) {
  "use strict";

  // Simple Contact Form
  $("#widget-contact-form-bottom").validate({

    submitHandler: function(form) {

      $(form).ajaxSubmit({
        success: function(text) {
          if (text.response == 'success') {
            $.notify({
              message: "Twoja wiadomość <strong>pomyślnie</strong> została do nas wysłana. Skontaktujemy się z Tobą tak szybko, jak to możliwe."
            }, {
              type: 'success'
            });
            $(form)[0].reset();

          } else {
            $.notify({
              message: text.message
            }, {
              type: 'danger'
            });
          }
        }
      });
    }
  });

})(jQuery);
